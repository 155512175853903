/* Ensure that the html and body cover the full height of the viewport */
html,
body {
  height: 100%;
  margin: 0;
}

/* Container for the entire news page */
.news-container {
  text-align: center;
  padding: 40px 20px;
  background-color: white;
  min-height: 100vh; /* Ensure it spans at least the full height of the viewport */
  margin-bottom: 230px;
}

/* Header image styling */
.news-header-image {
  width: 100%; /* Make the image responsive */
  max-width: 600px; /* Limit the maximum width of the image */
  margin: 0 auto; /* Center the image */
  display: block; /* Ensures the image is block-level */
  margin-bottom: 20px; /* Space below the image */
}

/* Newsletter Button styles */
.newsletter-btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.newsletter-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.newsletter-btn:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

/* News articles container */
.news-article {
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

/* Article date styling */
.news-date {
  color: #777;
  font-size: 0.9em;
  margin-bottom: 10px;
}

/* Styling for social media share buttons */
.share-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
  margin-top: 20px;
  margin-bottom: 60px;
  justify-content: center;
}

.share-buttons i {
  font-size: 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.share-buttons i:hover {
  color: #007bff;
}

/* News article headings and content styling */
.news-article h2 {
  margin-bottom: 10px;
}

.news-article p {
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Read More Button */
.read-more-btn {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #0056b3;
}

/* Load More Button */
.load-more-btn {
  padding: 12px 24px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 100px;
}

.load-more-btn:hover {
  background-color: #218838;
}

/* Responsive adjustments */

/* For tablets and smaller screens */
@media (max-width: 1024px) {
  .news-container {
    padding: 20px;
  }

  /* Slightly smaller margins for news articles */
  .news-article {
    margin-bottom: 25px;
  }
}

/* For small screens like tablets and mobile portrait */
@media (max-width: 768px) {
  .news-container {
    padding: 15px;
  }

  /* Smaller margins for news articles */
  .news-article {
    margin-bottom: 20px;
  }

  /* Adjust font size for headlines */
  .news-article h2 {
    font-size: 1.5em;
  }

  .news-article p {
    font-size: 1em;
  }

  .share-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .share-buttons i {
    font-size: 18px;
  }
}

/* For very small screens like mobile phones */
@media (max-width: 480px) {
  /* Ensure text is readable on small screens */
  .news-article h2 {
    font-size: 1.3em;
  }

  .news-article p {
    font-size: 0.9em;
  }

  .share-buttons i {
    font-size: 16px;
    padding: 5px;
  }

  .load-more-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}
