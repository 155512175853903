html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.privacy-policy-container {
  padding: 20px;
  max-width: 800px;
  margin-top: 20px;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 10%;
  background-color: #f9f9f9;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  height: auto;
}

.privacy-policy-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.privacy-policy-container h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  text-align: center;
}

.privacy-policy-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 300px;
  text-align: center;
}

.last-updated {
  font-size: 0.9rem;
  text-align: center;
  margin-top: auto;
  padding-bottom: 20px; /* Reduced padding for smaller screens */
  margin-bottom: 20px; /* Reduced margin for smaller screens */
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 15px;
    max-width: 90%;
    margin: 15px auto;
  }

  .privacy-policy-container h1 {
    font-size: 1.8rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.4rem;
  }

  .privacy-policy-container p {
    font-size: 0.95rem;
  }

  .last-updated {
    padding-bottom: 30px; /* Adjust for smaller screens */
    margin-bottom: 30px; /* Adjust for smaller screens */
  }
}

@media (max-width: 480px) {
  .privacy-policy-container {
    padding: 10px;
    max-width: 95%;
    margin: 10px auto;
  }

  .privacy-policy-container h1 {
    font-size: 1.6rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.3rem;
  }

  .privacy-policy-container p {
    font-size: 0.875rem;
  }

  .last-updated {
    font-size: 0.8rem;
    padding-bottom: 10px; /* Reduce padding for mobile */
    margin-bottom: 10px; /* Reduce margin for mobile */
  }
}
