/* html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.button-container {
  position: relative;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #282c34;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
}

nav.nav-open {
  transform: translateX(0);
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin-top: 70px;
}

nav ul li {
  margin: 20px 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

nav ul li a:hover {
  text-decoration: underline;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

main {
  margin-top: 70px;
  padding: 20px;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
} */

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.button-container {
  position: relative;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #282c34;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
}

nav.nav-open {
  transform: translateX(0);
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin-top: 70px;
}

nav ul li {
  margin: 20px 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

nav ul li a:hover {
  text-decoration: underline;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

main {
  margin-top: 70px;
  padding: 20px;
  overflow-y: auto; /* Added for vertical scrolling */
  height: calc(100vh - 70px - 60px); /* Adjusted height calculation */
}

footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}
