/* .green-button {
  background-color: #27ae60;
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.green-button:hover {
  background-color: #1e8449;
} */

.green-button {
  background-color: #27ae60;
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.green-button:hover {
  background-color: #1e8449;
}
