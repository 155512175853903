/* The FAQ Page */
.faq-page {
  margin: 0 auto;
  text-align: center;
  background-image: url("../images/NFTbackground3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.7);
  min-height: 100vh;
}

/* Image Styling for the FAQ Header */
.faq-header-image {
  width: 50%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove inline spacing */
  margin-top: 0; /* Ensure no extra margin above */
  margin-bottom: 30px; /* Space below the image */
  margin-left: auto;
  margin-right: auto;
}

/* Title Styling */
.faq-page h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
}

/* FAQ Item Styling */
.faq-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s;
  max-width: 600px; /* Limit the width of each FAQ item */
  margin-left: auto; /* Center the FAQ item */
  margin-right: auto; /* Center the FAQ item */
}

/* When active, show this background */
.faq-item.active {
  background-color: #ddd;
}

/* FAQ Title */
.faq-item h3 {
  margin: 0;
}

/* FAQ Content */
.faq-item p {
  margin-top: 5px;
  display: none;
}

.faq-item.active p {
  display: block;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .faq-page {
    padding: 100px 20px;
  }

  .faq-page h1 {
    font-size: 1.5rem;
  }

  .faq-item {
    padding: 12px;
    max-width: 500px; /* Smaller max-width for tablets */
  }

  .faq-item h3 {
    font-size: 1.3rem;
  }

  .faq-item p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .faq-page {
    padding: 80px 20px;
  }

  .faq-page h1 {
    font-size: 1.2rem;
  }

  .faq-item {
    padding: 10px;
    max-width: 90%; /* Make the FAQ item nearly full width on mobile */
  }

  .faq-item h3 {
    font-size: 1.1rem;
  }

  .faq-item p {
    font-size: 0.9rem;
  }
}
