/* .partners-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 230px;
}

.partners-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  min-height: 420px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
}

.partner:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.partner-image {
  max-width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.partner-text {
  text-align: center;
  font-size: 4rem;
}

.partner-text h2 {
  margin: 0;
  font-size: 1.5rem;
  color: blue;
}

.partner-text p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: black;
  max-height: 9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  pointer-events: none;
}

.partner-link {
  text-decoration: none;
  color: inherit;
}
.partner-item {
  width: 320px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-logo {
  width: auto;
  max-width: 250px;
  height: 150px;
  object-fit: contain;
  display: block;
  margin-bottom: 10px;
}

.partner-item:nth-child(1) .partner-logo,
.partner-item:nth-child(2) .partner-logo,
.partner-item:nth-child(3) .partner-logo {
  max-width: 250px;
}

.partner-category {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: black;
}

.partners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  align-items: center;
  margin-bottom: 50px;
}

.partner-item {
  width: 320px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-logo.large {
  max-width: 300px;
  height: 180px;
  justify-content: center;
  text-align: center;
}

.partner-logo.medium {
  max-width: 250px;
  height: 150px;
}

.partner-logo.small {
  max-width: 200px;
  height: 120px;
}

.partner-logo.mini {
  max-width: 150px;
  height: 100px;
}

.partner-description {
  font-size: 1rem;
  color: black;
  margin-top: 10px;
  text-align: center;
}

.partner-link-2 {
  color: blue;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 100px;
}

.partner-link-2:hover {
  color: darkblue;
}

@media (max-width: 768px) {
  .partners-container {
    padding: 10px;
  }

  .partners-content {
    flex-direction: column;
  }

  .partner {
    width: 100%;
    max-width: 100%;
    min-height: auto;
    padding: 10px;
  }

  .partner-logo {
    max-width: 200px;
    height: auto;
  }

  .partner-text {
    font-size: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .partners-container {
    padding: 15px;
  }

  .partner {
    width: 45%;
  }

  .partner-logo {
    max-width: 230px;
  }
}

@media (min-width: 1024px) {
  .partner {
    width: 320px;
  }
} */

/* General container for partners section */
/* .partners-container {
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.supporters-title-image {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px;
  display: block;
}

.partners-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  min-height: 420px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
}

.partner:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.partner-image {
  max-width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.partner-text {
  text-align: center;
  font-size: 4rem;
}

.partner-text h2 {
  margin: 0;
  font-size: 1.5rem;
  color: blue;
}

.partner-text p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: black;
  max-height: none;
  overflow: visible;
  text-overflow: unset;
  display: block;
  pointer-events: none;
}

.partner-link {
  text-decoration: none;
  color: inherit;
}

.partner-category {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: white;
}

.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: blink 5s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
} */

/* General styling for the partners section */
/* .partners-container {
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.supporters-title-image {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px;
  display: block;
}

.donate-button-container {
  margin: 20px 0;
}

.partners-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  min-height: 420px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
}

.partner:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.partner-image {
  max-width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.partner-text {
  text-align: center;
  font-size: 4rem;
}

.partner-text h2 {
  margin: 0;
  font-size: 1.5rem;
  color: blue;
}

.partner-text p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: black;
  max-height: none;
  overflow: visible;
  text-overflow: unset;
  display: block;
  pointer-events: none;
}

.partner-link {
  text-decoration: none;
  color: inherit;
}

.partner-category {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: white;
}

.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: blink 5s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.partner-logo.large {
  max-width: 300px;
  height: 180px;
  justify-content: center;
  text-align: center;
}

.partner-logo.medium {
  max-width: 250px;
  height: 150px;
}

.partner-logo.small {
  max-width: 200px;
  height: 120px;
}

.partner-logo.mini {
  max-width: 150px;
  height: 100px;
}

.partner-description {
  font-size: 1rem;
  color: black;
  margin-top: 10px;
  text-align: center;
}

.partner-category-2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
  color: white;
}

.partners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.partner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.partner-item a {
  text-decoration: none;
}

.partner-item img {
  border-radius: 10px;
}

.partner-subtitle {
  font-size: 1.5rem;
  margin-top: 30px;
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .partners-content {
    flex-direction: column;
  }

  .partner {
    max-width: 90%;
  }
} */

/* General styling for the partners section */
.partners-container {
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.supporters-title-image {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px;
  display: block;
}

.donate-button-container {
  margin: 20px 0;
}

.partners-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  min-height: 420px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  box-sizing: border-box;
}

.partner:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.partner-image {
  max-width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.partner-text {
  text-align: center;
  font-size: 4rem;
}

.partner-text h2 {
  margin: 0;
  font-size: 1.5rem;
  color: blue;
}

.partner-text p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: black;
  max-height: none;
  overflow: visible;
  text-overflow: unset;
  display: block;
  pointer-events: none;
}

.partner-link {
  text-decoration: none;
  color: inherit;
}

.partner-category {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  color: white;
}

.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: blink 5s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.partner-logo.large {
  max-width: 350px;
  height: 210px;
  justify-content: center;
  text-align: center;
}

.partner-logo.medium {
  max-width: 300px;
  height: 180px;
}

.partner-logo.small {
  max-width: 250px;
  height: 150px;
}

.partner-logo.mini {
  max-width: 200px;
  height: 120px;
}

.partner-description {
  font-size: 1rem;
  color: black;
  margin-top: 10px;
  text-align: center;
}

.partner-category-2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
  color: white;
}

.partners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.partner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.partner-item a {
  text-decoration: none;
}

.partner-item img {
  border-radius: 10px;
}

.partner-subtitle {
  font-size: 1.5rem;
  margin-top: 30px;
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .partners-content {
    flex-direction: column;
  }

  .partner {
    max-width: 90%;
  }
}
