/* .home-container {
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  color: black;
  padding: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 300px;
}

.home-container h1 {
  font-size: 4rem;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
}

.home-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.home-video {
  max-width: 35%;
  height: auto;
  margin-right: 35px;
  margin-left: 35px;
  border-radius: 8px;
}

.home-text-block {
  flex: 1;
  background-color: white;
  padding: 20px;
  margin-right: 35px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.whitelist-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .home-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-video {
    max-width: 90%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .home-text-block {
    max-width: 90%;
    margin-right: 0;
    margin-left: 0;
  }

  .home-container h1 {
    font-size: 2.5rem;
  }

  .whitelist-button-container {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .home-container h1 {
    font-size: 2rem;
  }

  .home-video {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .home-text-block {
    font-size: 1rem;
    padding: 15px;
  }

  .whitelist-button-container {
    width: 100%;
  }
} */

/* Home Container */
.home-container {
  min-height: 100vh; /* Ensures full viewport height */
  background-size: cover;
  background-position: center;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 0;
  overflow: hidden; /* Prevent overflow */
}

/* Header Image */
.clipped-image {
  width: 60%; /* Keep it at 60% for large screens */
  max-width: 40%;
  height: auto;
  display: block;
  margin: 20px 0; /* Reduced space around the image */
  position: relative;
  z-index: 2; /* Keeps it above other elements */
  flex-shrink: 0;
}

/* Title Styling */
.home-container h1 {
  font-size: 4rem;
  margin-bottom: 20px; /* Reduced margin */
  text-align: center;
  width: 100%;
}

/* Home Content Layout */
.home-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 10px; /* Reduced top margin for better alignment */
}

/* Video Styling */
.home-video {
  max-width: 35%;
  height: auto;
  margin-right: 35px;
  margin-left: 35px;
  border-radius: 8px;
}

/* Text Block */
.home-text-block {
  flex: 1;
  background-color: white;
  padding: 20px;
  margin-right: 35px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Whitelist Button */
.whitelist-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
    align-items: center;
  }

  .clipped-image {
    max-width: 80%; /* Image size adjusts */
    margin-top: 10px;
  }

  .home-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-video {
    max-width: 90%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .home-text-block {
    max-width: 90%;
    margin-right: 0;
    margin-left: 0;
  }

  .home-container h1 {
    font-size: 2.5rem;
  }

  .whitelist-button-container {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 480px) {
  .home-container h1 {
    font-size: 2rem;
  }

  .home-video {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .home-text-block {
    font-size: 1rem;
    padding: 15px;
  }

  .whitelist-button-container {
    width: 100%;
  }

  .clipped-image {
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 10px; /* Controlled bottom margin */
  }
}
