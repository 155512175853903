/* footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
}

.footer-content {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

footer a {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
  pointer-events: auto;
}

footer a:hover {
  text-decoration: underline;
}

.contact-link {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
}

.contact-link:hover {
  text-decoration: underline;
}

.footer-logo-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position: relative;
}

.footer-logo-img {
  width: 150px;
}

@media (min-width: 768px) {
  footer {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 50px;
  }

  .footer-content {
    text-align: center;
    flex: 1;
    margin-bottom: 0;
  }

  .footer-logo-container {
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin-top: 0;
  }

  .footer-logo-img {
    width: 100px;
    margin-right: 50px;
  }
}

@media (max-width: 767px) {
  .footer-logo-container {
    justify-content: center;
    margin-top: 20px;
  }

  .footer-logo-img {
    width: 100px;
  }
} */

.footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  position: relative; /* Updated for positioning logo */
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px; /* Add some space for logo */
  padding-left: 20px;
  flex-direction: column; /* Center content vertically */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-link {
  color: #61dafb;
  margin: 0 15px;
  text-decoration: none;
  font-size: 16px;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-text {
  font-size: 14px;
  margin-top: 10px;
  color: #aaa;
}

/* Styles for the email collection section */
.email-collection {
  margin-top: 15px;
}

.email-text {
  font-size: 16px;
  color: #fff;
}

.email-link {
  color: #61dafb;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

/* Logo styles */
.footer-logo {
  position: absolute;
  right: 30px; /* Add a 30px margin to the right */
  bottom: 20px; /* Adjust the vertical position as needed */
}

.footer-logo-img {
  width: 100px; /* Adjusted size of the logo to 150px */
}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack the content vertically */
    padding: 10px; /* Reduce padding to prevent overflow */
  }

  .footer-logo {
    position: relative;
    right: 0; /* Remove the 50px margin on smaller screens */
    bottom: 20px;
    margin-top: 10px; /* Space it out from the text */
  }

  .footer-logo-img {
    width: 150px; /* Keep the size of the logo 150px on small screens */
  }

  .footer-links {
    margin-bottom: 20px; /* Add more space between links and text */
  }
}
