html,
body {
  height: 100%; /* Ensure the page occupies full viewport height */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  display: flex;
  flex-direction: column;
}

/* Main container with flex layout for better content flow */
.terms-of-use-container {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #f9f9f9;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: visible;
  flex-grow: 1; /* Let the container grow to fill the space */
  display: flex;
  flex-direction: column;
  word-wrap: break-word; /* Ensure text wraps properly */
  height: auto; /* Let content grow with the page size */
}

/* Heading and paragraph styles */
.terms-of-use-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.terms-of-use-container p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Footer / Last updated section styling */
.last-updated {
  font-size: 0.9rem;
  text-align: center;
  margin-top: auto; /* Pushes the "Last Updated" section to the bottom */
  margin-bottom: 200px; /* Space between last updated text and bottom of the screen */
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .terms-of-use-container {
    padding: 15px;
    max-width: 90%;
    margin: 10px auto;
  }

  .terms-of-use-container h1 {
    font-size: 1.8rem;
  }

  .terms-of-use-container p {
    font-size: 0.9rem;
  }
}

/* Media Query for Mobile Screens */
@media (max-width: 480px) {
  .terms-of-use-container {
    padding: 10px;
    max-width: 95%;
    margin: 5px auto;
  }

  .terms-of-use-container h1 {
    font-size: 1.5rem;
  }

  .terms-of-use-container p {
    font-size: 0.875rem;
  }

  .last-updated {
    font-size: 0.8rem;
    margin-bottom: 300px; /* Adjust bottom margin for mobile */
  }
}
