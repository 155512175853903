/* html,
body {
  height: 100%;
  margin: 0;
}

.peopleinvolved-container {
  height: 100%;
  min-height: 100vh;
  background-image: url("../images/NFTbackground6.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.peopleinvolved-container h1 {
  font-size: 6vw;
  margin-bottom: 20px;
  color: white;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 100px;
  gap: 20px;
}

.team-member {
  margin: 20px;
  text-align: center;
  position: relative;
  flex-basis: calc(33.33% - 40px);
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 230px;
}

@media screen and (max-width: 1024px) {
  .team-member {
    flex-basis: calc(50% - 40px);
  }

  .peopleinvolved-container h1 {
    font-size: 6vw;
  }

  .member-description {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .team-member {
    flex-basis: calc(50% - 40px);
  }

  .peopleinvolved-container h1 {
    font-size: 8vw;
  }

  .member-description {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .team-member {
    flex-basis: 100%;
    max-width: 100%;
  }

  .peopleinvolved-container h1 {
    font-size: 10vw;
  }

  .member-description {
    font-size: 2rem;
  }
}

.image-link {
  display: block;
  width: 100%;
  height: 200px;
  position: relative;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.initial-image,
.hover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.hover-image {
  opacity: 0;
}

.image-link:hover .hover-image {
  opacity: 1;
}

.image-link:hover .initial-image {
  opacity: 0;
}

.name-link {
  display: block;
  margin-top: 10px;
  font-size: 1.5rem;
  color: blue;
  text-decoration: none;
}

.name-link:hover {
  text-decoration: underline;
}

.member-description {
  margin-top: 10px;
  font-size: 1rem;
  color: black;
  background-color: white;
  padding: 10px;
  width: 100%;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 10px;
  height: auto;
} */

/* Ensure body and html take full height */
html,
body {
  height: 100%;
  margin: 0;
}

/* PeopleInvolved container with background image covering entire page */
.peopleinvolved-container {
  height: 100%;
  min-height: 100vh;
  background-image: url("../images/NFTbackground6.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

/* Styling for the team title image */
.team-title-image {
  width: 100%;
  max-width: 800px; /* Adjust based on your design */
  margin: 0 auto 20px; /* Centers the image and adds bottom margin */
  display: block;
}

/* Team Container */
.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 100px;
  gap: 20px; /* Added gap for spacing between items */
}

/* Individual Team Member */
.team-member {
  margin: 20px;
  text-align: center;
  position: relative;
  flex-basis: calc(33.33% - 40px); /* 3 columns on larger screens */
  max-width: 300px; /* Maximum width */
  display: flex;
  flex-direction: column; /* Stack image and description vertically */
  align-items: center; /* Center align items */
  padding: 0 10px; /* Added padding for proper spacing */
  margin-bottom: 230px;
}

/* Responsive design for large tablets and laptops */
@media screen and (max-width: 1024px) {
  .team-member {
    flex-basis: calc(50% - 40px); /* Two columns on larger screens */
  }

  .peopleinvolved-container h1 {
    font-size: 6vw; /* Slightly smaller font for larger screens */
  }

  .member-description {
    font-size: 1.2rem; /* Smaller font for larger screens */
  }
}

/* Responsive design for tablets and smaller screens */
@media screen and (max-width: 768px) {
  .team-member {
    flex-basis: calc(50% - 40px); /* Two columns on medium screens */
  }

  .peopleinvolved-container h1 {
    font-size: 8vw; /* Larger font for medium screens */
  }

  .member-description {
    font-size: 1.5rem; /* Slightly larger text for tablets */
  }
}

/* Responsive design for smaller screens like phones */
@media screen and (max-width: 480px) {
  .team-member {
    flex-basis: 100%; /* One column on small screens */
    max-width: 100%;
  }

  .peopleinvolved-container h1 {
    font-size: 10vw; /* Larger font for smaller screens */
  }

  .member-description {
    font-size: 2rem; /* Larger text for phones */
  }
}

/* Image Link Styling */
.image-link {
  display: block;
  width: 100%;
  height: 200px; /* Fixed height for images */
  position: relative;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%; /* Set height to fill container */
}

.initial-image,
.hover-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the images cover the area without stretching */
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.hover-image {
  opacity: 0;
}

.image-link:hover .hover-image {
  opacity: 1;
}

.image-link:hover .initial-image {
  opacity: 0;
}

/* Name Link Styling */
.name-link {
  display: block;
  margin-top: 10px;
  font-size: 1.5rem; /* Slightly larger font size */
  color: blue;
  text-decoration: none;
}

.name-link:hover {
  text-decoration: underline;
}

/* Member Description Styling */
.member-description {
  margin-top: 10px;
  font-size: 1rem; /* Smaller font size by default */
  color: black;
  background-color: white;
  padding: 10px;
  width: 100%; /* Full width */
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 10px;
  height: auto; /* Make height flexible */
}
