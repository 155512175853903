.donate-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-bottom: 50px;
  background: peachpuff;
}

h1 {
  color: #2c3e50;
  font-size: 2rem;
}

p {
  font-size: 1.1rem;
  color: #555;
}

.crypto-donation {
  margin-top: 30px;
  padding: 20px;
  background-color: #e8f6ff;
  border-radius: 10px;
}

.crypto-donation h2 {
  color: #27ae60;
}

.crypto-donation ul {
  list-style: none;
  padding: 0;
}

.crypto-donation li {
  font-size: 1rem;
  margin: 8px 0;
}

.wallet {
  font-family: monospace;
  font-size: 0.9rem;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
}

.permission-form {
  margin-top: 30px;
  padding: 40px;
  background-color: #e8f6ff;
  border-radius: 10px;
}

.permission-form h2 {
  color: #27ae60;
}

.permission-form .form-group {
  margin-bottom: 15px;
}

.permission-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

.permission-form input[type="text"],
.permission-form input[type="url"],
.permission-form input[type="checkbox"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.permission-form input[type="checkbox"] {
  width: auto;
}

.donate-button {
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #e67e22;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.donate-button:hover {
  background-color: #d35400;
}

/* Media Queries for Responsiveness */

/* Tablet view: Adjust font sizes and layout for tablet screens */
@media (max-width: 768px) {
  .donate-container {
    padding: 15px;
    margin: 50px auto;
    max-width: 95%; /* Increase container width on smaller screens */
  }

  h1 {
    font-size: 1.8rem; /* Adjust font size for headings */
  }

  p {
    font-size: 1rem; /* Adjust paragraph size for readability */
  }

  .crypto-donation h2 {
    font-size: 1.5rem; /* Make donation heading slightly smaller */
  }

  .crypto-donation li,
  .permission-form label {
    font-size: 1rem; /* Adjust list item size */
  }

  .donate-button {
    font-size: 1rem; /* Adjust button font size */
    padding: 10px 15px; /* Adjust padding for smaller screens */
  }
}

/* Mobile view: Adjust layout for mobile screens */
@media (max-width: 480px) {
  .donate-container {
    padding: 10px;
    margin: 30px auto;
    max-width: 95%;
  }

  h1 {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }

  p {
    font-size: 0.95rem; /* Adjust paragraph font size */
  }

  .crypto-donation,
  .permission-form {
    padding: 10px; /* Adjust padding to prevent content from feeling cramped */
  }

  .crypto-donation h2,
  .permission-form h2 {
    font-size: 1.3rem; /* Adjust headings for smaller screens */
  }

  .crypto-donation li,
  .permission-form label {
    font-size: 0.9rem; /* Adjust list item size for mobile */
  }

  .donate-button {
    font-size: 1rem; /* Ensure button is appropriately sized */
    padding: 8px 15px; /* Smaller padding */
  }
}

iframe {
  width: 100%;
  height: 938px;
  border: none;
  margin-top: 30px;
  margin-bottom: 200px;
  border-radius: 10px;
}
