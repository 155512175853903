/* General styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}

/* NFT Project container with diagonal background */
.NFTproject-container {
  height: 100%;
  background: linear-gradient(
    135deg,
    #ffcc99 50%,
    #87ceeb 50%
  ); /* Peach to Skyblue diagonal gradient */
  color: black;
  padding-left: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 300px;
  box-sizing: border-box;
}

/* Header Image */
.NFTproject-header-image {
  width: 45%;
  height: 300px;
  object-fit: cover;
  display: block;
  margin: 20px 0;
  padding: 0;
  border-radius: 8px;
  align-self: center; /* Center the header image */
}

/* NFT Project content container */
.NFTproject-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  position: relative;
  padding-top: 20px; /* Ensure some space between header image and content */
}

/* Jumping text container styling */
.jumping-text-container {
  position: relative;
  display: inline-block;
  margin: 20px;
  text-align: center;
}

.jumping-text {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation-timing-function: ease-in-out;
  font-size: 14px;
  width: 100%;
}

/* Individual jumping image styling */
.NFTproject-jumping-image {
  width: 300px; /* 3x larger than original */
  height: auto;
  margin: 10px 20px;
  object-fit: contain;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.nft-6 {
  animation-name: jump-6;
}

.nft-12 {
  animation-name: jump-12;
  animation-delay: 0.5s;
}

.nft-13 {
  animation-name: jump-13;
  animation-delay: 1s;
}

/* Jumping animations for images */
@keyframes jump-6 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px); /* Adjust to match the image movement */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes jump-12 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px); /* Adjust to match the image movement */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes jump-13 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px); /* Adjust to match the image movement */
  }
  100% {
    transform: translateY(0);
  }
}

/* Aligning text jumping with respective images */
.nft-6 + .jumping-text {
  animation-name: jump-text-6;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.nft-12 + .jumping-text {
  animation-name: jump-text-12;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.nft-13 + .jumping-text {
  animation-name: jump-text-13;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/* Text jump animations */
@keyframes jump-text-6 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px); /* Match the jump height of NFTweb6 */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes jump-text-12 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px); /* Match the jump height of NFTweb12 */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes jump-text-13 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-90px); /* Match the jump height of NFTweb13 */
  }
  100% {
    transform: translateY(0);
  }
}

/* NFT Project Text Block (right side) */
.NFTproject-text-block {
  background-color: white; /* Restored the white background */
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 700px;
  flex-grow: 1;
  margin-left: 40px; /* Offset to allow space for left-side jumping images */
}

/* Media Query for Tablets and Small Laptops */
@media (max-width: 768px) {
  .NFTproject-header-image {
    width: 80%;
    height: 250px;
  }

  .NFTproject-text-block {
    max-width: 90%;
  }

  .NFTproject-container {
    flex-direction: column;
    align-items: center;
  }

  .NFTproject-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .NFTproject-left-side {
    position: relative;
    bottom: initial;
    left: initial;
    justify-content: center;
  }

  .NFTproject-jumping-image {
    margin: 10px;
  }

  .NFTproject-text-block {
    margin-left: 0;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .NFTproject-header-image {
    width: 100%;
    height: 200px;
  }

  .NFTproject-text-block h2 {
    font-size: 1.25rem;
  }

  .NFTproject-text-block p {
    font-size: 1rem;
  }

  .NFTproject-text-block {
    padding: 10px;
  }

  .NFTproject-container {
    height: 100%;
  }

  .NFTproject-left-side {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
