/* Styling for the Gallery Container */
.gallery-container {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  color: black;
  padding: 0; /* Remove any padding */
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0; /* Remove margins */
  background-image: url("../images/NFTbackground3.jpg");
}

/* Styling for the Header Image */
.gallery-header-image {
  width: 100%; /* Ensure the image takes up 100% of the container width */
  max-width: 800px; /* Set a maximum width to prevent it from getting too large */
  height: auto; /* Maintain aspect ratio */
  margin: 0; /* Remove any margin around the image */
  display: block; /* Ensure it behaves as a block element */
  margin-left: auto;
  margin-right: auto; /* Center the image */
  transform: scale(1.7); /* Enlarge the image by 1.7x */
  object-fit: contain; /* Ensure aspect ratio is preserved */
}

/* Gallery Content */
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  border-radius: 8px;
  width: 100%;
  margin: 0; /* Remove margins */
}

/* Individual Image Wrapper */
.image-wrapper {
  position: relative;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  width: 200px;
  height: auto;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  transition: transform 0.2s;
  border-radius: 8px;
}

.image-wrapper:hover img {
  transform: scale(1.1);
}

/* Text Below Images */
.image-text {
  margin-top: 10px;
  font-size: 1.2rem;
  color: white;
  text-align: center;
}

/* Social Media Share Icons */
.social-share {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-share a {
  color: white;
  font-size: 1.5rem;
}

.social-share a:hover {
  color: #61dafb;
}

/* Text Area for Gallery Description */
.gallery-text {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 1.2rem;
  border-radius: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  text-align: left;
  flex-grow: 1;
  min-height: 150px;
  box-sizing: border-box;
  overflow: visible;
}

/* Media Queries */

/* For Small Screens (Mobile Devices) */
@media (max-width: 768px) {
  .gallery-container {
    padding: 10px;
  }

  .gallery {
    gap: 15px;
  }

  .image-wrapper {
    width: 150px;
    margin: 0; /* Remove margin */
  }

  .image-text {
    font-size: 1rem;
  }

  .gallery-text {
    font-size: 1rem;
    padding: 15px;
    min-height: 150px;
  }

  .social-share a {
    font-size: 1.2rem;
  }

  /* Adjust the gallery header image for small screens */
  .gallery-header-image {
    width: 90%; /* Reduce width for small screens */
    max-width: 600px; /* Smaller max-width for smaller screens */
    transform: scale(1.5); /* Slightly reduce the scale on small screens */
  }
}

/* For Medium Screens (Tablets) */
@media (min-width: 768px) and (max-width: 1024px) {
  .gallery-container {
    padding: 15px;
  }

  .image-wrapper {
    width: 180px;
  }

  .gallery-text {
    font-size: 1.1rem;
    min-height: 180px;
  }

  /* Adjust the gallery header image for medium screens */
  .gallery-header-image {
    width: 85%; /* Reduce width slightly on medium screens */
    max-width: 700px; /* Adjust the max-width */
    transform: scale(1.6); /* Scale image slightly less than desktop */
  }
}

/* For Large Screens (Desktops) */
@media (min-width: 1024px) {
  .gallery-container {
    padding: 20px;
  }

  .image-wrapper {
    width: 200px;
  }

  .gallery-header-image {
    width: 100%; /* Full width on larger screens */
    max-width: 800px; /* Default max-width */
    transform: scale(1.7); /* Default scaling */
  }
}

/* For Extra Large Screens */
@media (min-width: 1200px) {
  .gallery-container {
    padding: 30px;
  }

  .image-wrapper {
    width: 250px;
  }

  .gallery-header-image {
    width: 100%; /* Full width on extra-large screens */
    max-width: 900px; /* Larger max-width for extra large screens */
    transform: scale(1.7); /* Keep scaling consistent */
  }
}
