/* CodersAidFund Container */
.codersaidfund-container {
  min-height: 100vh;
  background: linear-gradient(
    to right,
    #0000ff 50%,
    /* Blue on the left */ #f5f5dc 50% /* Beige on the right */
  );
  color: black;
  padding-left: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Center the content horizontally */
  box-sizing: border-box;
  margin-bottom: 300px;
  width: 100%;
}

/* Style for the image above the text block */
.codersaidfund-image {
  max-width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  object-fit: contain;
  display: block;
}

/* Main content layout */
.codersaidfund-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px; /* Spacing between image and text block */
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
}

/* Style for the left-side image */
.codersaidfund-image-left {
  max-width: 35%; /* Image size should stay as is */
  height: auto;
  margin: 20px;
  border-radius: 8px;
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
  order: 0; /* Keeps image on the left */
  margin-left: 5%;
}

/* Style for the text block */
.codersaidfund-text-block {
  flex: 1;
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  order: 1; /* Ensure text block is on the right */
  margin-left: 30%; /* Push the text block to the right side */
  margin-right: 0; /* Ensure no margin on the right side */
}

/* Media Query for Tablets and Small Laptops */
@media (max-width: 768px) {
  .codersaidfund-container h1 {
    font-size: 2.5rem;
  }

  .codersaidfund-image-left {
    max-width: 80%; /* Scale image more on smaller devices */
    margin: 10px 0; /* Adjust margin for smaller screens */
  }

  .codersaidfund-text-block {
    max-width: 90%; /* Ensures text block fits inside the container */
  }

  .codersaidfund-content {
    flex-direction: column; /* Stack the content vertically on smaller screens */
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px; /* Add gap between the image and the text */
  }

  .codersaidfund-text-block h2 {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
  }

  .codersaidfund-text-block p {
    font-size: 1rem; /* Adjust font size for readability */
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .codersaidfund-container h1 {
    font-size: 2rem;
  }

  .codersaidfund-image-left {
    max-width: 100%; /* Ensures the image scales down correctly on mobile */
    margin: 10px 0; /* Adjust margin on mobile */
  }

  .codersaidfund-text-block h2 {
    font-size: 1.25rem;
  }

  .codersaidfund-text-block p {
    font-size: 1rem;
  }

  .codersaidfund-text-block {
    padding: 10px; /* Adjust padding on mobile */
    max-width: 100%; /* Ensure it takes full width on smaller screens */
  }

  /* Ensures container has full height on mobile */
  .codersaidfund-container {
    height: auto; /* Ensure content takes full height of viewport */
    padding-bottom: 30px; /* Adds some padding at the bottom for visibility */
  }
}
